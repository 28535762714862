:root {
    /* Shared Colors */
    --background-color-light: #F8F8F8;
    --background-color-dark: #282828;
    --background-color-dark-bg: #1C1C1C;
    --text-color-light: #1C1C1C;
    --text-color-dark: #F8F8F8;
    --primary-color: #FF4500;
    --shadow-color: rgba(0, 0, 0, 0.1);
  
    /* Sidebar */
    --sidebar-background-light: var(--background-color-light);
    --sidebar-background-dark: var(--background-color-dark);
    --sidebar-text-light: var(--text-color-light);
    --sidebar-text-dark: #E0E0E0;
  
    /* Navbar */
    --navbar-background-light: var(--background-color-light);
    --navbar-background-dark: var(--background-color-dark);
  
    /* Links */
    --link-color-light: var(--text-color-light);
    --link-color-dark: #E0E0E0;
    --link-hover-background-light: #575757;
    --link-hover-background-dark: #575757;
  
    /* Code Blocks */
    --code-background-light: #dcdcdc;
    --code-background-dark: #555555;
  
    /* Dropdowns */
    --dropdown-background-light: var(--background-color-light);
    --dropdown-background-dark: var(--background-color-dark);
    --dropdown-text-light: var(--text-color-light);
    --dropdown-text-dark: var(--sidebar-text-dark);
  
    /* Footer */
    --footer-background-light: var(--background-color-light);
    --footer-background-dark: var(--background-color-dark);
  }
  
  /* Body */
  body {
    font-family: 'Inter', sans-serif;
    background-color: var(--background-color-light);
    color: var(--text-color-light);
    transition: background-color 0.5s ease, color 0.5s ease;
  }
  
  body.dark-mode {
    background-color: var(--background-color-dark-bg);
    color: var(--text-color-dark);
  }
  
  /* Code Blocks */
  code {
    font-family: "IBM Plex Mono", monospace;
    margin: 10px 0;
    padding: 10px;
    background-color: var(--code-background-light);
    border-radius: 10px;
    display: block;
    box-sizing: border-box;
  }
  
  body.dark-mode code {
    background-color: var(--code-background-dark);
  }
  
  p code {
    color: var(--primary-color);
    padding: 2px 5px;
    border-radius: 5px;
    display: inline;
    white-space: nowrap;
  }
  
  /* Navbar */
  .navbar {
    height: 50px;
    width: 100%;
    background-color: var(--navbar-background-light);
    box-shadow: 0 2px 5px var(--shadow-color);
    transition: background-color 0.5s ease, color 0.5s ease;
  }
  
  body.dark-mode .navbar {
    background-color: var(--navbar-background-dark);
  }
  
  .navbar-brand {
    color: var(--text-color-light);
    text-decoration: none;
    font-weight: bold;
    font-size: 25px;
    padding-left: 10px;
    transition: color 0.5s ease;
  }
  
  body.dark-mode .navbar-brand {
    color: var(--text-color-dark);
  }
  
  /* Sidebar */
  .sidebar {
    background-color: var(--sidebar-background-light);
    color: var(--sidebar-text-light);
    height: 100%;
    width: 25vh;
    padding: 20px;
    box-shadow: 2px 0 5px var(--shadow-color);
    transition: background-color 0.5s ease, color 0.5s ease;
  }
  
  body.dark-mode .sidebar {
    background-color: var(--sidebar-background-dark);
    color: var(--sidebar-text-dark);
  }
  
  /* Sidebar Links */
  .nav-link {
    color: var(--link-color-light);
    padding: 10px 15px;
    border-radius: 4px;
    margin-bottom: 5px;
    transition: background-color 0.5s ease, color 0.5s ease;
    display: flex;
    align-items: center;
  }
  
  body.dark-mode .nav-link {
    color: var(--link-color-dark);
  }
  
  .sidebar .nav-link.active,
  .sidebar .nav-link:hover {
    background-color: var(--link-hover-background-light);
    color: var(--text-color-dark);
  }
  
  body.dark-mode .sidebar .nav-link.active,
  body.dark-mode .sidebar .nav-link:hover {
    background-color: var(--link-hover-background-dark);
  }
  
  .navlink-icon {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    font-size: 1.2rem;
  }
  
  /* Buttons */
  
  .button-light:hover,
  .button-dark:hover {
    background-color: var(--primary-color);
    color: #ffffff;
  }
  
  .btn-watchdog {
    color: #FF4500;
    background-color: transparent;
    border: 1px solid #FF4500;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 5px;
  }
  
  .btn-watchdog:hover {
    color: #ffffff;
    background-color: #FF4500;
    border: 1px solid #FF4500;
    font-size: 12px;
    font-weight: bold;
  }
  
  .btn-watchdog-noborder {
    color: #FF4500;
    background-color: transparent;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .btn-watchdog-noborder:hover {
    color: #ffffff;
    background-color: #FF4500;
    font-size: 12px;
    font-weight: bold;
  }
  
  /* Footer */
  .dark-mode .footer {
    background-color: var(--footer-background-dark);
    color: var(--text-color-dark);
  }
  
  .footer {
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    width: 100%;
  }
  
  /* Dropdown Menu */
  .dropdown-menu-right {
    width: 350px;
    right: 0;
    left: auto;
    margin-right: 5px;
    margin-top: 2px;
    background-color: var(--dropdown-background-light);
    transition: background-color 0.5s ease;
    font-size: 14px;
  }
  
  body.dark-mode .dropdown-menu-right {
    background-color: var(--dropdown-background-dark);
  }
  
  .dropdown-item {
    padding: 8px 15px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .dropdown-userimg {
    padding: 10px;
  }
  
  .dropdown-icon {
    padding-right: 15px;
  }
  
  .dropdown-item:hover {
    background-color: var(--link-hover-background-light);
    color: var(--text-color-dark);
  }
  
  body.dark-mode .dropdown-item:hover {
    background-color: var(--link-hover-background-dark);
  }
  
  .dropdown-header {
    background-color: var(--dropdown-background-light);
    color: var(--dropdown-text-light);


  }
  
  body.dark-mode .dropdown-header {
    background-color: var(--dropdown-background-dark);
    color: var(--dropdown-text-dark);
  }
  
  /* Card */
  .card {
    margin-top: 15px;
    border-radius: 10px;
    box-shadow: 0 1px 4px var(--shadow-color);
    transition: background-color 0.5s ease, color 0.5s ease;
  }
  
  body.dark-mode .card {
    background-color: var(--background-color-dark);
    color: var(--text-color-dark);
  }
  
  /* Utility Classes */
  .wd-alert {
    margin-top: 10px;
    font-size: 14px;
  }
  
  .fs-8 {
    font-size: 10px;
  }
  
  .fs-7 {
    font-size: 13px;
  }